<div class="parent-modal">
  <div class="modal-title-header">
    <div class="mwc-upload-text-icon mwc-icon-highlight-primary-lg">
      <span mwc-icon mwcIconfont="edit-04" style="font-size: 20px"></span>
    </div>
    <div>
      <div class="mwc-text-lg-semibold mwc-color-gray-900">Add Context for Your Content Generation
      </div>
      <span class="mwc-text-sm-regular mwc-color-gray-600">Help Content Studio refine the generated content precisely to your expectations</span>
    </div>
  </div>
  <form mwc-form [formGroup]="generateAiForm" class="generateAiForm">
  <mwc-tabset [mwcTabBarExtraContent]="extraTemplate">
    <mwc-tab mwcTitle="Topic Details">
      <mwc-form-item>
        <mwc-form-label [mwcSpan]="24">{{titleText}}?</mwc-form-label>
        <mwc-form-control>
          <input placeholder="{{placeholderText}}" [(ngModel)]="inputText" formControlName="description" mwc-input
            class="mwc-text-sm-regular">
        </mwc-form-control>
      </mwc-form-item>
      <mwc-form-item *ngIf="!isGenericFlow">
        <mwc-form-label [mwcSpan]="24">What’s the related core topic of your {{selectedTemplateTitle}}?</mwc-form-label>
        <mwc-form-control>
          <input placeholder="Enter a related core topic of your article" formControlName="coreTopic" mwc-input
            class="mwc-text-sm-regular">
        </mwc-form-control>
      </mwc-form-item>
      <mwc-form-item style="position: relative;margin-bottom: 0px;">
        <mwc-form-label [mwcSpan]="24">What are the keywords you would like to target?</mwc-form-label>
        <mwc-form-control>
          <div class="selected-keywords">
            <mwc-tag *ngFor="let selectedKeyword of selectedKeywords; let i = index" [mwcMode]="'closeable'"
              (mwcOnClose)="removeKeyword(selectedKeyword)" class="mwc-input-tag-list mwc-text-xs-medium" mwc-tooltip
              [mwcTooltipTitle]="selectedKeyword"> {{
              sliceKeyword(selectedKeyword) }} </mwc-tag>
            <span *ngIf="!keywordInputVisible &&!(selectedKeywords.length >= maxKeywordsAllowed)" class="editable-tag"
              mwcNoAnimation (click)="showKeywordInput()" class="mwc-text-sm-regular mwc-color-gray-400"
              style="width:150px;display:inline-block">+ Add More
              Keyword</span>
            <input #inputElement mwc-input mwcSize="small" *ngIf="keywordInputVisible" type="text"
              [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedKeywordInputValue"
              (blur)="addKeywordToSelectedKeywords()" (keydown.enter)="addKeywordToSelectedKeywords()"
              class="mwc-tag-input mwc-text-sm-regular" />
          </div>
        </mwc-form-control>
        <div class="generate-btn generate-btn-with-count" [ngStyle]="{ 'flex-direction': (!isSaasUser && !hasKeywordPlanner) ? 'row-reverse' : 'row' }">
          <div *ngIf="!(!isSaasUser && !hasKeywordPlanner)">
            <button mwc-tooltip
            [mwcTooltipTitle]="(selectedKeywords.length >= maxKeywordsAllowed ) ? 'You have already added a maximum of 6 keywords':''"
            [mwcSize]="'small'"
            [disabled]="(selectedKeywords.length >= maxKeywordsAllowed) || !(generateAiForm.controls['description'].value?.length > 0) || (!hasKeywordPlanner)"
            mwc-button (click)="selectYourKeywordModal(false,null,true)" mwcType="default-color">
              <span mwc-icon mwcIconfont="stars-02"></span>Generate
            </button>
            <div class="upgrade-plan-msg" *ngIf="!hasKeywordPlanner && isSaasUser">
              <mwc-alert mwcType="default" [mwcMessage]="msgText" [mwcIcon]="customIconTemplate" mwcShowIcon="true"></mwc-alert>
              <ng-template #customIconTemplate>
                <span mwc-icon mwcIconfont="info-circle" style="font-size: 18px;color: #475467;"></span>
              </ng-template>
              <ng-template #msgText>
                <div class="mwc-text-sm-regular mwc-color-gray-700">
                  <a target="_blank" style="text-decoration: underline;" class="mwc-text-sm-regular mwc-color-gray-700" (click)="openCustomerPortal('subscription_update')">Upgrade</a>  to Enable Keyword Recommendations
                </div>
              </ng-template>
            </div>
          </div>
          <div class="mwc-text-xs-regular">{{selectedKeywords.length}}/{{maxKeywordsAllowed}}</div>
        </div>
      </mwc-form-item>
      <mwc-form-item style="margin: 16px  0 5px;">
        <div class="content-brief">
          <div class="mwc-text-sm-medium mwc-color-gray-700">{{contentBriefText}}?</div>
          <mwc-switch mwcSize="small" [ngModelOptions]="{standalone: true}"
            (ngModelChange)="onBriefSwitchChanged($event)" [ngModel]="isContentBriefEnabled"></mwc-switch>
          <!-- <mwc-tag [mwcColor]="'primary'" (click)="openPromptRepository()"
            style="display: flex;align-items: center;cursor: pointer;font-weight: 600;"><span mwc-icon
              mwcIconfont="ai-bulb" style="font-size: 15px;margin-right: 5px;"></span>Prompt Guide</mwc-tag> -->
        </div>
        <mwc-form-control *ngIf="isContentBriefEnabled" style="margin-top: 6px">
          <textarea formControlName="contentBrief" mwc-input rows="5" [placeholder]="contentBriefPlaceholder"
            style="resize: none;" class="mwc-text-sm-regular"></textarea>
        </mwc-form-control>
      </mwc-form-item>
    </mwc-tab>
    <ng-container *ngIf="isadvancedSettingsShow">
      <mwc-tab mwcTitle="Language">
        <mwc-form-item>
          <mwc-form-label [mwcSpan]="24" mwcFor="language">Select Base Language</mwc-form-label>
          <mwc-form-control [mwcSpan]="24">
            <div>
              <mwc-select class="select-language" mwcSize="small" mwcDropdownClassName="select-language-dropdown"
                [mwcShowArrow]="true" [(ngModel)]="selectedValueLanguage" formControlName="language"
                [mwcCustomTemplate]="defaultTemplate" mwcPlaceHolder="Select language"
                class="mwc-xs-select mwc-text-sm-medium select-language" [mwcOptionHeightPx]="36"
                (ngModelChange)='languageChanged($event)'>
                <mwc-option *ngFor="let item of provinceData" mwcCustomContent [mwcLabel]="item.label"
                  [mwcValue]="item.value">
                  {{item.label}} <span mwc-icon [mwcIconfont]="item.value"></span>
                </mwc-option>
              </mwc-select>
              <ng-template #defaultTemplate let-selected>
                <span mwc-icon [mwcIconfont]="selected.mwcValue"></span>
                {{ selected.mwcLabel }}
              </ng-template>
            </div>
          </mwc-form-control>
        </mwc-form-item>
      </mwc-tab>
      <mwc-tab mwcTitle="Brand Voice">
        <ng-container *ngIf="isNoCreateBrandVoice">
          <div class="empty-screen-branch-voice">
            <div><img src="../../assets/images/empty-box.svg" /></div>
            <div>
              <div class="mwc-text-md-medium mwc-color-gray-700 pb-2">No Brand Voice Found!</div>
              <div class="mwc-text-sm-regular mwc-color-gray-600">Looks like you don’t have any brand voices yet. Let’s create new and get started.</div>
            </div>
            <button mwc-button mwcType="default" mwcSize="small" (click)="openRedirectToMPC('brandVoice')"><span mwc-icon mwcIconfont="plus" style="font-size: 18px;"></span>Create Brand Voice</button>
          </div>
        </ng-container>
        <ng-container *ngIf="!isNoCreateBrandVoice">
          <mwc-form-item>
            <div style="display: flex;align-items: center;justify-content: space-between;">
              <mwc-form-label mwcFor="brandVoice">Select Brand Voice</mwc-form-label>
              <div>
                <button mwc-button mwcType="link" (click)="reloadList('brandVoice')" style="margin-right: 8px;"><span mwc-icon mwcIconfont="refresh-ccw-01" style="font-size: 16px;"></span> Reload </button>
                <button mwc-button mwcType="link-color" (click)="openRedirectToMPC('brandVoice')"><span mwc-icon mwcIconfont="settings-02" style="font-size: 18px;"></span> Manage </button>
              </div>
            </div>
            <mwc-form-control [mwcSpan]="24"> 
              <div>
                <mwc-select mwcShowSearch [mwcServerSearch]="true" (mwcOnSearch)="brandVoiceSearch($event)" class="select-layout mwc-xs-select mwc-text-sm-medium" mwcSize="small" [mwcShowArrow]="true" [(ngModel)]="selectedBrandVoice" formControlName="brandVoice" mwcPlaceHolder="Select a brand voice" [mwcDropdownClassName]="['mwc-xs-select-dropdown','brand-voice-dropdown']" [mwcOptionHeightPx]="37" (ngModelChange)="brandVoiceChange($event)">
                  <mwc-option *ngFor="let item of filteredBrandVoiceList" mwcCustomContent [mwcLabel]="item.label" [mwcValue]="item.value">
                    <div class="brand-voice-list">
                      <div class="mwc-text-sm-regular mwc-color-gray-900">{{item.label}}</div>
                      <div style="display: flex;align-items: center;gap:8px">
                        <div><span class="mwc-text-xs-regular mwc-color-gray-500">Tone: </span><span class="mwc-text-xs-medium mwc-color-gray-700">{{item.tone}}</span></div>
                        <div><span class="mwc-text-xs-regular mwc-color-gray-500">Created: </span><span class="mwc-text-xs-medium mwc-color-gray-700">{{getDaysDifference(item.createdOn)}}</span></div>
                      </div>
                    </div>
                  </mwc-option>
                </mwc-select>
                <label mwc-checkbox class="mt-1" [mwcSize]="'small'" [(mwcChecked)]="isDefaultBVAvailable"><span class="mwc-text-xs-medium">Save my selection</span></label>
              </div>
            </mwc-form-control>
          </mwc-form-item>
          <ng-container *ngIf="selectedBrandVoice">
            <div style="width: 100%;margin-top: 16px; padding-left: 16px; padding-right: 16px; padding-top: 12px; padding-bottom: 12px; background: white; border-radius: 12px; border: 1px #D0D5DD solid;">
              <div style="display: flex;align-items: center;gap:8px">
                <div style="width: 40px; height: 40px; padding: 10px; background: #EAECF0; border-radius: 20px; justify-content: center; align-items: center; display: flex">
                  <div style="display: flex; color: #667085">
                    <span mwc-icon mwcIconfont="clipboard-report"></span>
                  </div>
                </div>
                <div>
                  <div class="mwc-text-md-medium mwc-color-gray-700">{{brandVoiceDetails?.label}}</div>
                  <div style="display: flex;align-items: center;gap:8px">
                    <div><span class="mwc-text-xs-regular mwc-color-gray-500">Tone: </span><span class="mwc-text-xs-medium mwc-color-gray-700">{{brandVoiceDetails?.tone}}</span></div>
                    <div><span class="mwc-text-xs-regular mwc-color-gray-500">Created By: </span><span class="mwc-text-xs-medium mwc-color-gray-700">{{brandVoiceDetails?.createdBy}}</span></div>
                    <div><span class="mwc-text-xs-regular mwc-color-gray-500">Created: </span><span class="mwc-text-xs-medium mwc-color-gray-700">{{getDaysDifference(brandVoiceDetails?.createdOn)}}</span></div>
                  </div>
                </div>
              </div>
              <div style="margin: 12px 0 0;">
                <div  class="mwc-text-sm-regular mwc-color-gray-500" [innerHTML]="brandVoiceDetails.brandVoiceDocument" style="white-space: pre-wrap;"></div>
                <!-- <textarea mwc-input id="post_message" formControlName="brandVoiceDocument" style="resize: none;border: none;padding: 0;transition: height 300ms ease-out;overflow: hidden;height: calc(100vh - 520px);outline: none;box-shadow: none;" class="mwc-text-sm-regular" #contentTextarea readonly></textarea>
                <button mwc-button mwcType="link-color" (click)="toggleContent()" > {{ isExpanded ? 'see less' : 'read more' }} </button> -->
              </div>
            </div>
          </ng-container>
        </ng-container>
      </mwc-tab>
      <mwc-tab mwcTitle="Target Audience">
        <ng-container *ngIf="isNoTargetAudience">
          <div class="empty-screen-branch-voice">
            <div><img src="../../assets/images/empty-box.svg" /></div>
            <div>
              <div class="mwc-text-md-medium mwc-color-gray-700 pb-2">No Target Audience Found!</div>
              <div class="mwc-text-sm-regular mwc-color-gray-600">Looks like you don’t have any target audiences yet. Let’s 
                <br/>create new and get started.</div>
            </div>
            <button mwc-button mwcType="default" mwcSize="small" (click)="openRedirectToMPC('targetAudience')"><span mwc-icon mwcIconfont="plus" style="font-size: 18px;"></span>Create Target Audience</button>
          </div>
        </ng-container>
        <ng-container *ngIf="!isNoTargetAudience">
          <mwc-form-item>
            <div style="display: flex;align-items: center;justify-content: space-between;">
              <mwc-form-label mwcFor="targetAudience">Select Target Audience</mwc-form-label>
              <div>
                <button mwc-button mwcType="link" (click)="reloadList('targetAudience')" style="margin-right: 8px;"><span mwc-icon mwcIconfont="refresh-ccw-01" style="font-size: 16px;"></span> Reload </button>
                <button mwc-button mwcType="link-color" (click)="openRedirectToMPC('targetAudience')"><span mwc-icon mwcIconfont="settings-02" style="font-size: 18px;"></span> Manage </button>
              </div>
            </div>
            <mwc-form-control [mwcSpan]="24">
              <div>
                <mwc-select mwcShowSearch  class="select-layout mwc-xs-select mwc-text-sm-medium" mwcSize="small" [mwcShowArrow]="true" [(ngModel)]="selectedTargetAudience" formControlName="targetAudience" mwcPlaceHolder="Select a target audience" [mwcDropdownClassName]="['mwc-xs-select-dropdown','target-audience-dropdown']" [mwcOptionHeightPx]="57" (ngModelChange)="targetAudienceChange($event)">
                  <mwc-option *ngFor="let item of targetAudienceList" mwcCustomContent [mwcLabel]="item.title" [mwcValue]="item.title">
                    <div class="brand-voice-list">
                      <div style="max-width: 350px;">
                        <div class="mwc-text-sm-regular mwc-color-gray-900" mwc-typography mwcEllipsis style="min-width: 150px;margin: 0;">{{item.title}}</div>
                        <div><span class="mwc-text-xs-regular mwc-color-gray-500">Created: </span><span class="mwc-text-xs-medium mwc-color-gray-700">{{getDaysDifference(item.createdDate)}}</span></div>
                      </div>
                      <div style="text-align: right;">
                        <div>
                          <span class="mwc-text-xs-regular mwc-color-gray-500">Gender: </span>
                          <ng-container *ngFor="let gender of item.thingTargetAudience.thingJson.gender;let last = last; let first = first;">
                            <span class="mwc-text-xs-medium mwc-color-gray-700">{{gender}}</span> <span *ngIf="!last" class="mx-1">|</span>
                          </ng-container>
                        </div>
                        <div>
                          <span class="mwc-text-xs-regular mwc-color-gray-500">Age group: </span>
                          <ng-container *ngFor="let age of item.thingTargetAudience.thingJson.ageGroup;let last = last;">
                            <span class="mwc-text-xs-medium mwc-color-gray-700">{{age}}</span>
                            <span *ngIf="!last" class="mx-1">|</span>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                  </mwc-option>
                </mwc-select>
                <label mwc-checkbox class="mt-1" [mwcSize]="'small'" [(mwcChecked)]="isDefaultTAAvailable"><span class="mwc-text-xs-medium">Save my selection</span></label>
              </div>
              <ng-template #ageTooltipTemplate let-ageData>
                <div class="mwc-text-xs-medium mwc-color-gray-700" *ngFor="let remaingAge of ageData">{{remaingAge}}</div>
              </ng-template>
            </mwc-form-control>
          </mwc-form-item>
          <ng-container *ngIf="selectedTargetAudience">
            <div style="width: 100%;margin-top: 16px; padding-left: 16px; padding-right: 16px; padding-top: 12px; padding-bottom: 12px; background: white; border-radius: 12px; border: 1px #D0D5DD solid;">
              <div style="display: flex;align-items: center;gap:8px">
                <div style="min-width: 40px; height: 40px; padding: 10px; background: #EAECF0; border-radius: 20px; justify-content: center; align-items: center; display: flex">
                  <div style="display: flex; color: #667085">
                    <span mwc-icon mwcIconfont="users-03"></span>
                  </div>
                </div>
                <div>
                  <div class="mwc-text-md-medium mwc-color-gray-700" mwc-typography mwcEllipsis style="margin: 0;width: 86%;">{{targetAudienceDetails?.title}}</div>
                  <div style="display: flex;align-items: center;gap:8px">
                    <div><span class="mwc-text-xs-regular mwc-color-gray-500">Created By: </span><span class="mwc-text-xs-medium mwc-color-gray-700">{{targetAudienceDetails?.authorName}}</span></div>
                    <div><span class="mwc-text-xs-regular mwc-color-gray-500">Created: </span><span class="mwc-text-xs-medium mwc-color-gray-700">{{getDaysDifference(targetAudienceDetails.createdDate)}}</span></div>
                  </div>
                </div>
              </div>
              <div style="margin-top: 12px;border-top: 1px solid #efefef;padding-top: 10px;">
                <div mwc-row [mwcGutter]="[24,12]">
                  <div mwc-col [mwcSpan]="12">
                    <div class="mwc-text-sm-regular mwc-color-gray-500 mb-1"> Age Group: </div>
                    <ng-container>
                      <div class="view-layout">
                        <ng-container *ngFor="let age of targetAudienceDetails.thingTargetAudience.thingJson.ageGroup;let last = last; let first = first;">
                        <span class="mwc-text-sm-medium mwc-color-gray-700">{{age}}</span> <span *ngIf="!last" class="mx-1">|</span>
                        </ng-container>
                      </div>
                    </ng-container>
                  </div>
                  <div mwc-col [mwcSpan]="12">
                    <div class="mwc-text-sm-regular mwc-color-gray-500 mb-1">Family Status:</div>
                    <ng-container>
                      <div class="view-layout">
                      <div *ngFor="let status of targetAudienceDetails.thingTargetAudience.thingJson.relationshipStatus;let last = last; let first = first;">
                      <span class="mwc-text-sm-medium">{{status}}</span> <span *ngIf="!last" class="mx-1">|</span>
                      </div>
                    </div>
                    </ng-container>
                  </div>
                  <div mwc-col [mwcSpan]="12">
                    <div class="mwc-text-sm-regular mwc-color-gray-500 mb-1">Gender:</div>
                    <ng-container>
                      <div class="view-layout">
                      <div *ngFor="let gender of targetAudienceDetails.thingTargetAudience.thingJson.gender;let last = last; let first = first;">
                      <span class="mwc-text-sm-medium">{{gender}}</span> <span *ngIf="!last" class="mx-1">|</span>
                      </div>
                    </div>
                    </ng-container>
                  </div>
                  <div mwc-col [mwcSpan]="12">
                    <div class="mwc-text-sm-regular mwc-color-gray-500 mb-1">Intent:</div>
                    <div><span class="mwc-text-sm-medium">{{targetAudienceDetails.thingTargetAudience.thingJson.intent}}</span></div>
                  </div>
                  <div mwc-col [mwcSpan]="12">
                    <div class="mwc-text-sm-regular mwc-color-gray-500 mb-1">Industry:</div>
                    <ng-container>
                      <div class="view-layout">
                      <div *ngFor="let industry of targetAudienceDetails.thingTargetAudience.thingJson.industry;let last = last; let first = first;">
                      <span class="mwc-text-sm-medium">{{industry}}</span> <span *ngIf="!last" class="mx-1">|</span>
                      </div>
                    </div>
                    </ng-container>
                  </div>
                  <div mwc-col [mwcSpan]="12">
                    <div class="mwc-text-sm-regular mwc-color-gray-500 mb-1">Interests:</div>
                    <div style="display: flex;gap:16px;align-items: center;">
                      <ng-container>
                        <div class="view-layout">
                        <div *ngFor="let interests of targetAudienceDetails.thingTargetAudience.thingJson.interests;let last = last; let first = first;">
                        <span class="mwc-text-sm-medium">{{interests}}</span> <span *ngIf="!last" class="mx-1">|</span>
                        </div>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </mwc-tab>
      <!-- <mwc-tab mwcTitle="Author">
        <mwc-form-item>
          <mwc-form-label [mwcSpan]="24" mwcFor="language">Select Author Persona</mwc-form-label>
          <mwc-form-control [mwcSpan]="24">
            <div>
              <mwc-select  mwcSize="small"
                [mwcShowArrow]="true" [(ngModel)]="selectedValueLanguage" formControlName="selectedValueTone"
              mwcPlaceHolder="Select Author Persona"
                class="mwc-xs-select mwc-text-sm-medium"
                (ngModelChange)='languageChanged($event)'>
                <mwc-option  mwcLabel="Front-Office Manager"
                  mwcValue="brand_voice_1">
                </mwc-option>
                <mwc-option  mwcLabel="Marketing Manager"
                  mwcValue="brand_voice_2">
                </mwc-option>
              </mwc-select>
            </div>
          </mwc-form-control>
        </mwc-form-item>
        <mwc-space></mwc-space>
        <div style="width: 100%;margin-top: 16px; height: 104px; padding-left: 16px; padding-right: 16px; padding-top: 12px; padding-bottom: 12px; background: white; border-radius: 12px; border: 1px #D0D5DD solid; justify-content: flex-start; align-items: flex-start; gap: 12px; display: inline-flex">
          <div style="width: 40px; height: 40px; padding: 10px; background: #EFF4FF; border-radius: 8px; justify-content: center; align-items: center; display: flex">
            <div style="display: flex; color: #004EEB">
              <span mwc-icon mwcIconfont="user-check-01"></span>
            </div>
          </div>
          <div style="flex: 1 1 0; flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 12px; display: inline-flex">
            <div style="align-self: stretch; height: 80px; flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 4px; display: flex">
              <div style="align-self: stretch; color: #344054; font-size: 14px; font-family: Inter; font-weight: 500; line-height: 20px; word-wrap: break-word">By choosing an author persona you can adopt a specific identity and style to communicate consistently with a target audience through voice, tone, and style choices.By chosing an author persona you can adopt a specific identity and style to communicate consistently with a target audience through voice, tone, and style choices.</div>
            </div>
          </div>
        </div>
      </mwc-tab> -->
    </ng-container>

  </mwc-tabset>
  <ng-template #extraTemplate>
    <!-- <mwc-switch mwcSize="small" [ngModelOptions]="{standalone: true}" (ngModelChange)="toggelAdvancedSettings($event)"
      [ngModel]="isadvancedSettingsShow"></mwc-switch> Advanced -->
      <div><a mwc-button mwcType="link-color" (click)="toggelAdvancedSettings()"><span mwc-icon [mwcIconfont]="!isadvancedSettingsShow ? 'eye':'eye-off'"></span> {{!isadvancedSettingsShow ? 'Show Preferences':'Hide Preferences'}}</a></div>
  </ng-template>
  </form>

  <!-- <form mwc-form [formGroup]="generateAiForm" class="generateAiForm">
    
  
    <div >
      <mwc-form-item>
        <mwc-form-label [mwcSpan]="24" mwcFor="tone">Select a Tone </mwc-form-label>
        <mwc-form-control [mwcSpan]="24">
          <mwc-select class="select-layout" mwcSize="small" [mwcShowArrow]="true" [(ngModel)]="selectedValueTone"
            formControlName="tone" mwcPlaceHolder="Select a Tone" [mwcDropdownClassName]="'mwc-xs-select-dropdown'"
            class="mwc-xs-select mwc-text-sm-medium" [mwcOptionHeightPx]="36" (ngModelChange)='toneChanged($event)'>
            <mwc-option *ngFor="let item of toneData" mwcCustomContent [mwcLabel]="item.label" [mwcValue]="item.value">
              {{item.label}}
            </mwc-option>
          </mwc-select>
        </mwc-form-control>
      </mwc-form-item>
      <mwc-divider style="margin: 16px 0;"></mwc-divider>
      <div class="mwc-text-sm-medium" style="margin-bottom:16px">Target Audience</div>
      <mwc-form-item>
        <mwc-form-label [mwcSpan]="24" mwcFor="gender">Gender </mwc-form-label>
        <mwc-form-control [mwcSpan]="24">
          <mwc-select class="select-layout" mwcSize="small" [mwcShowArrow]="true" [(ngModel)]="selectedValueGender"
            formControlName="gender" mwcPlaceHolder="Select a Gender" [mwcDropdownClassName]="'mwc-xs-select-dropdown'"
            class="mwc-xs-select mwc-text-sm-medium" [mwcOptionHeightPx]="36">
            <mwc-option *ngFor="let item of genderList" mwcCustomContent [mwcLabel]="item.label"
              [mwcValue]="item.value">
              {{item.label}}
            </mwc-option>
          </mwc-select>
        </mwc-form-control>
      </mwc-form-item>

      <mwc-form-item>
        <mwc-form-label [mwcSpan]="24" mwcFor="gender">Age Group </mwc-form-label>
        <mwc-form-control [mwcSpan]="24">
          <mwc-select class="select-layout" mwcSize="small" [mwcShowArrow]="true" [(ngModel)]="selectedValueAgeGroup"
            formControlName="ageGroup" mwcPlaceHolder="Select a Age Group"
            [mwcDropdownClassName]="'mwc-xs-select-dropdown'" class="mwc-xs-select mwc-text-sm-medium"
            [mwcOptionHeightPx]="36">
            <mwc-option *ngFor="let item of ageGroupList" mwcCustomContent [mwcLabel]="item.label"
              [mwcValue]="item.value">
              {{item.label}}
            </mwc-option>
          </mwc-select>
        </mwc-form-control>
      </mwc-form-item>
      <mwc-form-item *ngIf="isContentIntelligenceEnabled">
        <mwc-form-label [mwcSpan]="24" mwcFor="gender">Interests & Personas </mwc-form-label>
        <mwc-form-control [mwcSpan]="24">
          <div style="display: flex; flex-direction: row;gap: 16px;align-items: center;">
            <mwc-select class="select-layout" mwcSize="small" [mwcShowArrow]="true"
              [(ngModel)]="selectedValueInterestAndPersona" formControlName="interestsPersonals"
              mwcPlaceHolder="Select a Interests and Personals" [mwcDropdownClassName]="'mwc-xs-select-dropdown'"
              class="mwc-xs-select mwc-text-sm-medium" [mwcOptionHeightPx]="36">
              <mwc-option *ngFor="let item of interestsPersonalsList" mwcCustomContent [mwcLabel]="item.label"
                [mwcValue]="item.value">
                {{item.label}}
              </mwc-option>
            </mwc-select>
            <a mwc-button mwcSize="small" mwcType="link-color" (click)="onAddPersona(true)"
              *ngIf="!personaEnabled"><span mwc-icon mwcIconfont="plus"></span>Add Custom Persona</a>
            <input class="mwc-text-sm-regular input-layout" mwc-input mwcSize="small" placeholder="Add custom persona"
              *ngIf="personaEnabled" [(ngModel)]="addedPersona" [ngModelOptions]="{standalone: true}" />
            <a mwc-button mwcSize="small" mwcType="link-color" *ngIf="personaEnabled"
              (click)="onSavePersona(false)"><span mwc-icon mwcIconfont="save-01"></span>Save</a>
          </div>
        </mwc-form-control>
      </mwc-form-item>
    </div>
  </form> -->

  <div *mwcModalFooter>
    <div mwc-row mwcJustify="space-between">
      <div mwc-col mwcSpan="12">
        <button mwc-button mwcType="default" mwcBlock (click)="generateArticle(true)"
          [disabled]="!(generateAiForm.controls['description'].value?.length > 0)" [mwcLoading]="isLoading"><span
            mwc-icon mwcIconfont="stars-02"></span>Create Outline</button>
      </div>
      <div mwc-col mwcSpan="12">
        <button mwc-button mwcType="primary" mwcBlock (click)="generateArticle(false)"
          [disabled]="!(generateAiForm.controls['description'].value?.length > 0)" [mwcLoading]="isLoading"><span
            mwc-icon mwcIconfont="stars-02"></span>Generate</button>
      </div>
      <!-- <div mwc-col mwcSpan="24" *ngIf="isGenericFlow">
        <button i18n="@@bs-create-new-modal-form-generate-suggestions-button-text" mwc-button mwcType="primary"
          [disabled]="!(generateAiForm.controls['description'].value?.length > 0)" mwcBlock
          (click)="selectYourKeywordModal()" [mwcLoading]="isLoading">Generate Suggestions</button>
      </div> -->
    </div>
  </div>
</div>
<mwc-modal [(mwcVisible)]="isRedirectToMPC" mwcWidth="544px" [mwcCentered]="true" (mwcOnCancel)="redirectToMPCModalCancel()" [mwcFooter]="null">
  <ng-container *mwcModalContent>
    <div mwc-row [mwcGutter]="[16, 32]" style="padding-top: 24px; flex-flow: row;">
      <div mwc-col >        
        <div class="mwc-icon-highlight-primary-lg">
          <span mwc-icon mwcIconfont="link-external-02" style="font-size: 20px;"></span>
        </div>
      </div>
      <div mwc-col>
        <ng-container *ngIf="modalType == 'brandVoice'">
          <div class="mwc-text-lg-medium mwc-color-gray-900 mb-2">Redirect to business settings to manage Brand Voice</div>
          <p class="mwc-text-sm-regular mwc-color-gray-600 m-0">You'll be redirected to business settings to make changes to your Brand Voice. Once you're done, you can return here to continue your work.</p>
        </ng-container>
        <ng-container *ngIf="modalType == 'targetAudience'">
          <div class="mwc-text-lg-medium mwc-color-gray-900 mb-2">Redirect to business settings to manage Target Audience</div>
          <p class="mwc-text-sm-regular mwc-color-gray-600 m-0">You'll be redirected to business settings to make changes to your Target Audience. Once you're done, you can return here to continue your work.</p>
        </ng-container>
      </div>
    </div>
    <div style="display: flex;justify-content: right;gap: 16px;" class="p-2 pb-4">
      <button mwc-button mwcType="default" (click)="redirectToMPCModalCancel()" mwcSize="small" class="mwc-text-sm-regular">Cancel</button>
      <button mwc-button mwcType="primary" (click)="redirectToMPCModalOk()" mwcSize="small" class="mwc-text-sm-regular">Continue</button>
    </div>
  </ng-container>
</mwc-modal>
