import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { MwcDrawerRef } from 'mwc/drawer';
import { BehaviorSubject, Observable, map, tap } from 'rxjs';
import { HelperService } from 'src/app/_shared/services/helper/helper.service';
import { StorageHelper } from 'src/app/_utilities/storage-helper';
import { UserInfo } from '../../_shared/models/common';
import { CreateCustomerPortalRequest, CreateCustomerPortalResponse } from '../../_shared/models/article';
import { STRIPEACTIONS } from '../../_shared/consts/app-constants';
import { ArticleService } from '../article/article.service';



@Injectable({
  providedIn: 'root',
})
export class CommonService {
  // Private Properties
  private sessionInfoUrl = 'api/auth/GetSessionInfo';
  private logoutUrl = 'api/auth/Logout';
  private userInfoSubject = new BehaviorSubject<UserInfo | null>(null);
  private drawerRef: MwcDrawerRef<any, any>[] = [];

  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  private _storageHelper: StorageHelper;
  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    private helperService: HelperService,
    private articleService: ArticleService
  ) {
    this._storageHelper = new StorageHelper();
  }

  setDrawerRef(drawerRef: MwcDrawerRef<any, any>) {
    this.drawerRef.push(drawerRef);
  }

  destroyDrawerRef() {
    if (this.drawerRef) {
      this.drawerRef.forEach((ref: MwcDrawerRef) => {
        ref.close();
      });
      this.drawerRef = [];
    }
  }

  getUserInfoValue(): Observable<UserInfo> | null {
    return this.userInfoSubject.asObservable();
  }

  fetchUserInfo(): Observable<UserInfo> {
    return this.http.get<UserInfo>(this.baseUrl + this.sessionInfoUrl).pipe(
      tap(userInfo => this.userInfoSubject.next(userInfo))
    );
  }

  openCustomerPortal(actionType: string) {
    var createCustomerPortalRequest = new CreateCustomerPortalRequest();
    createCustomerPortalRequest.businessId = +this._storageHelper.user.bId;
    createCustomerPortalRequest.actionType = actionType;
    if (actionType == STRIPEACTIONS.SubscriptionUpdate) {
      createCustomerPortalRequest.returnUrl = this._storageHelper.user.returnUrl + "account/success-subscription?businessid=" + this._storageHelper.user.bId + "&actiontype=" + actionType + "";
    }
    else if (actionType == STRIPEACTIONS.PaymentMethodUpdate) {
      createCustomerPortalRequest.returnUrl = window.location.origin;
    } else if (actionType == STRIPEACTIONS.SubscriptionCancel) {
      createCustomerPortalRequest.returnUrl = this._storageHelper.user.returnUrl + "account/subscription-cancelled?businessid=" + this._storageHelper.user.bId + "&actiontype=" + actionType + "";
    }

    // Return an observable of stripeUrl
    return this.articleService.createCustomerPortal(createCustomerPortalRequest).pipe(
      map((res: CreateCustomerPortalResponse) => {
        if (res.errorCode === 0 && !res.errorMessage && res.stripeUrl) {
          return res.stripeUrl;
        } else {
          throw new Error('Failed to get stripeUrl');
        }
      })
    );
  }

  logout(): void {
    this.http.post<any>(`${this.baseUrl}${this.logoutUrl}`, null, { headers: this.headers })
      .subscribe({
        next: (response) => {
          if (response && response.access_token === "" && response.expires_in === "") {
            const returnUrl = this._storageHelper.user?.returnUrl ?? '/';
            localStorage.removeItem('user_email');
            localStorage.removeItem('_user');
            sessionStorage.removeItem('siteConfig');
            window.location.href = `${returnUrl}account/login`;
          }
        },
        error: (err) => {
          console.error('Error occurred during logout:', err);
        }
      });
  }
  
}
