export interface DamConfig {
  damEnabled: boolean;
  errorCode: number;
  errorMessage: string;
}

export interface User {
  success: boolean,
  message: string,
  accessToken: string,
  userId: number,
  userName: string,
  returnUrl: string,
  bId: string,
  damApiUrl: string,
  isDamEnabled: boolean,
  pId: number,  
  industryName: string,
  industryId: string,
  isCmsEnabled: boolean,
  profileName: string,
  cmsSiteUrl: string,
  //isContentIntelEnabled: boolean,
  //isContentGenEnabled: boolean,
  isAgencyUser: boolean,
  businessName:string;
  businessAlias:string;
  profileAlias:string;
  dprBaseUrl: string;
  businessThingId: string;
  userThingId: string;
  expiryTimestamp: number;
  referrer: string;
  brandVoiceUrl: string;
  targetAudienceUrl: string;
  businessInformationUrl: string;
  error: any;
}

export class VerifyUserRequest{
  businessId:number;
  profileAlias:string;
}

export class LoginResponse {
  access_token: string;
  refresh_token: string;
  expires_in: number;
  errorcode: number;
  errorMessage: number;
  rememberMe: boolean;
  url: string;
  token_type: string;
  cookie_prefix: string;
}

export class UserInfo {
  featureIds: string[];
  isSaasUser: boolean;
  userFirstName: string;
  userLastName: string;
  userRoleName: string;
}
