import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { HelperService } from '../../_shared/services/helper/helper.service';
import { StorageHelper } from '../../_utilities/storage-helper';
import { catchError, lastValueFrom, Observable, throwError, timeout } from 'rxjs';
import { GetDatasetFileRequest, GetSettingsFileRequest } from '../../_shared/models/getsettingsfilerequest';
import { GetSettingsFileResponse } from '../../_shared/models/getsettingsfileresponse';
import { ArticleHttpTimeouts } from '../../_shared/consts/article-constants';
import { UpdateAudienceRequest } from 'src/app/_shared/models/UpdateAudienceRequest';
import { GetAudiencesResponse, GetAudiencesResponseType } from 'src/app/_shared/models/GetAudiencesResponse';
import { GetAudiencesRequestType } from 'src/app/_shared/models/GetAudiencesRequest';
import { GetLineOfBusinessResponse } from '../../_shared/models/GetLineOfBusinesssResponse';
import { UpdateDatasetStatusRequest } from '../../_shared/models/UpdateDatasetStatusRequest';
import { GetDatasetsResponse } from '../../_shared/models/GetDatasetsResponse';
import { GetLineOfBusinesssRequest } from '../../_shared/models/GetLineOfBusinesssRequest';
import { UpdateDatasetStatusResponse } from '../../_shared/models/UpdateDatasetStatusResponse';
import { GetDatasetsRequest } from '../../_shared/models/GetDatasetsRequest';
import { GenerateDataSetRequest } from 'src/app/_shared/models/GenerateDataSetRequest';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  private _storageHelper: StorageHelper;
  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    private helperService: HelperService,
  ) {
    this._storageHelper = new StorageHelper();
  }
  public saveUploadsettings<T>(formData: FormData) {
    return this.http.post(
      `${this.baseUrl}api/configuration/savesettings`,
      formData,
      { headers: new HttpHeaders().append('Content-Disposition', 'multipart/form-data') });
  }

  public getSettings<T>(request: GetSettingsFileRequest) {
    return this.http.get<GetSettingsFileResponse>(
      `${this.baseUrl}api/configuration/getsettings?profileId=` + request.ProfileId,
      { headers: this.headers }
    );
  }
  public getSettingsFile<T>(request: GetSettingsFileRequest) {
    return this.http.get(this.baseUrl + 'api/configuration/getfile?profileId=' + request.ProfileId, { responseType: 'blob' },);
  }

  // get target Audiences
  public getAudiences<T>(request: GetAudiencesRequestType): Observable<GetAudiencesResponseType> {
    return this.http.post<GetAudiencesResponseType>(
      `${this.baseUrl}api/topic/getaudiences`,
      JSON.stringify(request),
      { headers: this.headers }
    );
  }

  // update target audience
  public updateAudience<T>(request: UpdateAudienceRequest) {
    return this.http.post<GetAudiencesResponseType>(
      `${this.baseUrl}api/topic/updateaudience`,
      JSON.stringify(request),
      { headers: this.headers }).pipe(
        catchError((error: HttpErrorResponse) => {
          console.log(error);
          throw error;
        })
      );
  }

  // get Line Of Businesss
  public getLineOfBusinesss<T>(request: GetLineOfBusinesssRequest) {
    return this.http.post<GetLineOfBusinessResponse>(
      `${this.baseUrl}api/Dataset/GetLineOfBusinesss`,
      JSON.stringify(request),
      { headers: this.headers }).pipe(
        catchError((error: HttpErrorResponse) => {
          console.log(error);
          throw error;
        })
      );
  }

  // get datasets
  public getDatasets<T>(request: GetDatasetsRequest) {
    return this.http.post<GetDatasetsResponse>(
      `${this.baseUrl}api/Dataset/GetDatasets`,
      JSON.stringify(request),
      { headers: this.headers }).pipe(
        catchError((error: HttpErrorResponse) => {
          console.log(error);
          throw error;
        })
      );
  }


  // Update Dataset Status
  public updateDatasetStatus<T>(request: UpdateDatasetStatusRequest) {
    return this.http.post<UpdateDatasetStatusResponse>(
      `${this.baseUrl}api/Dataset/UpdateDatasetStatus`,
      JSON.stringify(request),
      { headers: this.headers }).pipe(
        catchError((error: HttpErrorResponse) => {
          console.log(error);
          throw error;
        })
      );
  }

  // Download Dataset File
  public getDatasetFile<T>(request: GetDatasetFileRequest) {
    return this.http.get(this.baseUrl + 'api/Dataset/getdatasetfile?datasetId=' + request.DatasetId, { responseType: 'blob' },);
  }
  
  public generateDataset<T>(request: GenerateDataSetRequest) {
    return this.http.post<any>(
      `${this.baseUrl}api/Dataset/generatedataset`,
      JSON.stringify(request),
      { headers: this.headers }).pipe(
        catchError((error: HttpErrorResponse) => {
          console.log(error);
          throw error;
        })
      );
  }
}

