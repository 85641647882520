<mwc-layout class="layout">
  <mwc-sider mwcCollapsible [(mwcCollapsed)]="isCollapsed" [mwcTrigger]="null" [mwcWidth]="270"
    [mwcCollapsedWidth]="82">
    <div class="logo" style="height: 90px;"><img width="180" alt="logo" src="assets/images/logo-new.svg"></div>
    <!-- <ul mwc-menu [mwcSelectable]="true" class="blog_studio_menu">
      <li mwc-submenu
          [mwcPaddingLeft]="14"
          mwcOpen="(1==1)"
          mwcTitle="Content Studio"
          [mwcIcon]="updateIcon('layers-three-01')">

        <ul>

          <li mwc-menu-item [mwcSelected]="isMenuSelected('/dashboard')" *ngIf="isContentIntelEnable">
            <a [routerLink]="['/', 'dashboard']">Dashboard</a>
          </li>
          <li mwc-menu-item [mwcSelected]="isMenuSelected('/manage-content/articles')" *ngIf="isContentGenEnabled">
            <a [routerLink]="['/', 'manage-content', 'studio']">Articles</a>
          </li>
          <li mwc-menu-item class="text-menu">
            <div>
              <span class="mwc-color-gray-400 mwc-text-md-semibold">Web Copy</span>
              <span><mwc-tag [mwcColor]="'primary'">Coming Soon</mwc-tag></span>
            </div>
          </li>

          <li mwc-menu-item [mwcSelected]="isMenuSelected('/topic-ideas')" *ngIf="isContentIntelEnable">
            <a [routerLink]="['/', 'topic-ideas']">Topic Ideas</a>
          </li>

          <li mwc-menu-item [mwcSelected]="isMenuSelected('/settings')">
            <a [routerLink]="['/', 'settings']">Settings</a>
          </li>
        </ul>
      </li>
    </ul> -->
    <button mwc-button mwcType="primary" class="create-new-content" (click)="initTemplate()" style="gap: 4px !important;">
      <span mwc-icon mwcIconfont="plus"></span> Create New Content
    </button>
    <ul mwc-menu [mwcSelectable]="true" class="blog_studio_menu">
      <li mwc-menu-item [mwcSelected]="isMenuSelected('/dashboard')" *ngIf="isContentGenEnabled || isContentIntelEnable">
        <a [routerLink]="['/', 'dashboard']"><span mwc-icon mwcIconfont="bar-chart-square-03"></span>Dashboard</a>
      </li>
      <li mwc-menu-item [mwcSelected]="isMenuSelected('/topic-ideas')" *ngIf="!(!isContentIntelEnable && !IsSaaSUser)">
        <a [routerLink]="['/', 'topic-ideas']"><span mwc-icon mwcIconfont="lightbulb-02"></span>Topic Ideas <mwc-tag *ngIf="!isContentIntelEnable && IsSaaSUser" [mwcColor]="'gray'" [mwcSize]="'small'" style="margin-left: auto;margin-right: 0;"><span mwc-icon mwcIconfont="crown-01" style="font-size: 18px;"></span></mwc-tag></a>
      </li>
      <li mwc-menu-item [mwcSelected]="isMenuSelected('/templates')">
        <a [routerLink]="['/', 'templates']"><span mwc-icon mwcIconfont="layout-alt-03"></span>Templates</a>
      </li>
      <div mwc-menu-divider></div>
      <li mwc-menu-item [mwcSelected]="isMenuSelected('/manage-content/articles')"
        *ngIf="isContentGenEnabled || isContentIntelEnable">
        <a [routerLink]="['/', 'manage-content', 'studio']"><span mwc-icon mwcIconfont="file-02"></span>My Content</a>
      </li>
      <!-- <li mwc-menu-item class="text-menu">
        <div>
          <div class="mwc-color-gray-400 mwc-text-md-medium comming-soon"><span mwc-icon mwcIconfont="file-heart-03" style="    color: #98a2b3 !important;"></span>Social Posts</div>
          <div>
            <span><mwc-tag [mwcColor]="'primary'">Coming Soon</mwc-tag></span>
          </div>
        </div>
      </li>
      <li mwc-menu-item class="text-menu">
        <div>
          <div class="mwc-color-gray-400 mwc-text-md-medium comming-soon"><span mwc-icon mwcIconfont="image-plus" style="    color: #98a2b3 !important;"></span>Images</div>
          <div>
            <span><mwc-tag [mwcColor]="'primary'">Coming Soon</mwc-tag></span>
          </div>
        </div>
      </li> -->
      <li mwc-menu-item [mwcSelected]="isMenuSelected('/manage-content/articles/manage-category')">
        <a [routerLink]="['/', 'manage-content', 'articles', 'manage-category']"><span mwc-icon
            mwcIconfont="grid-01"></span>Categories</a>
      </li>
      <div mwc-menu-divider></div>
      <li mwc-menu-item [mwcSelected]="isMenuSelected('/settings')">
        <a [routerLink]="['/', 'settings']"><span mwc-icon mwcIconfont="settings-01"></span>Settings</a>
      </li>
      <!-- <li mwc-menu-item [mwcSelected]="isMenuSelected('/settings')">
        <a [routerLink]="['/', 'settings']"><span mwc-icon mwcIconfont="settings-01"></span>Settings</a>
      </li> -->
      <li mwc-menu-item [mwcSelected]="isMenuSelected('/support')">
        <a href="https://help.milestoneinternet.com/tutorial-ai-content-studio" target="_blank"><span mwc-icon
            mwcIconfont="life-buoy-01"></span>Knowledge Center</a>
      </li>
    </ul>
  </mwc-sider>
  <mwc-layout>
    <mwc-header class="fixed">
      <div class="blog-header">
        <div>
          <!-- <span class="trigger" mwc-icon [mwcIconfont]="isCollapsed ? 'menu-open' : 'menu-close'"
            (click)="isCollapsed = !isCollapsed"></span> -->
          <mwc-select style="display: none;" (ngModelChange)="languageChanged($event)" [ngModel]="selectedLanguage">
            <mwc-option [mwcLabel]="'en-US (English)'" [mwcValue]="'en'"></mwc-option>
            <mwc-option [mwcLabel]="'es-ES (Spanish)'" [mwcValue]="'es'"></mwc-option>
          </mwc-select>

          <div>
            <div mwc-dropdown mwcTrigger="click" [mwcDropdownMenu]="menu" class="profile-dropdown"
              [mwcClickHide]="false" [(mwcVisible)]="visible" mwcOverlayClassName="profile-select-dropdown">
              <button [disabled]="!(profiles.length > 1)" mwc-button mwcType="default">{{selectedProfileName}}
                <span mwc-icon mwcIconfont="chevron-down"></span></button>
            </div>
            <mwc-dropdown-menu #menu="mwcDropdownMenu">
              <div><app-profile-select [profiles]="profiles" [filteredProfiles]=" filteredProfiles"
                  [selectedProfileId]="selectedProfileId" (hideDropdown)="closeDropdown($event)"></app-profile-select>
              </div>
            </mwc-dropdown-menu>
          </div>
        </div>
        <div class="actions">
          <!-- <div class="mwc-color-gray-500" style="display: none;cursor: pointer;"><span mwc-icon mwcIconfont="home-line"
              style="font-size: 20px;"></span></div>
          <div class="mwc-color-gray-500" (click)="openPromptRepository()" style="cursor: pointer;"><span mwc-icon
                mwcIconfont="ai-bulb" style="font-size: 24px;"></span></div> -->
          <div class="mwc-color-gray-500" (click)="openKnowledgeBase()" style="cursor: pointer;"><span mwc-icon
              mwcIconfont="graduation-hat-02" style="font-size: 20px;"></span></div>
          <!-- <button class="mwc-color-gray-500 header-menu-button" mwc-dropdown mwcTrigger="click"
            [mwcDropdownMenu]="SchemaMenu" mwc-button mwcType="link" style="padding: 0;width: 20px;height: 20px;">
            <span mwc-icon mwcIconfont="dots-grid"></span>
          </button> -->
          <mwc-dropdown-menu #SchemaMenu="mwcDropdownMenu">
            <div class="outer-layout">
              <div class="inner-layout">
                <li mwc-menu-item (click)="mpcRedirect()"> <span mwc-icon mwcIconfont="home-line"
                    class="menu-icon"></span> <span class="mwc-color-gray-700 mwc-text-sm-medium"> Milestone Presence
                    Cloud
                  </span> </li>
                <li mwc-menu-item (click)="dprRedirect()"> <span mwc-icon mwcIconfont="trend-up-01"
                    class="menu-icon"></span> <span class="mwc-color-gray-700 mwc-text-sm-medium"> Digital Presence
                    Report</span> </li>
                <li mwc-menu-item *ngIf="isDamEnabled" (click)="assetLibraryRedirect()"> <span mwc-icon
                    mwcIconfont="image-01" class="menu-icon"></span> <span
                    class="mwc-color-gray-700 mwc-text-sm-medium"> Asset Library </span> </li>
              </div>
            </div>
          </mwc-dropdown-menu>
          <div class="user mwc-color-gray-500 user-avatar" mwc-dropdown mwcTrigger="click"
          [mwcDropdownMenu]="userMenu" [mwcClickHide]="false"><mwc-avatar mwcIcon="user-01" style="background: #F2F4F7;color: #667085;font-size: 20px;cursor: pointer;"></mwc-avatar></div>
          <mwc-dropdown-menu #userMenu="mwcDropdownMenu">
            <div class="outer-layout" style="width: 260px;">
              <div style="display: flex;align-items: center;padding: 12px 16px;gap: 12px;">
                <div class="user-avatar"><mwc-avatar mwcIcon="user-01" style="background: #F2F4F7;color: #667085;font-size: 20px;"></mwc-avatar></div>
                <div>
                  <div class="mwc-text-sm-medium mwc-color-gray-700">{{userName}}</div>
                  <div class="mwc-text-xs-medium mwc-color-gray-600" mwc-typography mwcEllipsis style="margin: 0;width: 180px;">{{userEmail}}</div>
                </div>
              </div>
              <div class="inner-layout">
                <li mwc-menu-item (click)="openAccountSettings()"> 
                  <span mwc-icon mwcIconfont="user-circle" class="menu-icon"></span> 
                  <span class="mwc-color-gray-700 mwc-text-sm-medium"> Account Settings </span> 
                </li>
                <li mwc-menu-item *ngIf="IsSaaSUser" mwc-popover [(mwcPopoverVisible)]="ManageSubscriptionSubMenuvisible" mwcPopoverTrigger="click" [mwcPopoverContent]="contentTemplate" mwcPopoverPlacement="left" [mwcPopoverOverlayClassName]="'ms-submenu'"> 
                  <span mwc-icon mwcIconfont="zap" class="menu-icon"></span> 
                  <span class="mwc-color-gray-700 mwc-text-sm-medium"> Manage Subscription </span> 
                  <span mwc-icon mwcIconfont="chevron-right"  style="margin-left: auto;font-size: 18px;"></span> 
                </li>
                <ng-template #contentTemplate>
                  <li mwc-menu-item (click)="openCustomerPortal('payment_method_update')"> 
                    <span mwc-icon mwcIconfont="currency-dollar-circle" class="menu-icon"></span> 
                    <span class="mwc-color-gray-700 mwc-text-sm-medium"> Billing </span> 
                  </li>
                  <li mwc-menu-item (click)="openCustomerPortal('subscription_update')"> 
                    <span mwc-icon mwcIconfont="switch-vertical-01" class="menu-icon"></span> 
                    <span class="mwc-color-gray-700 mwc-text-sm-medium"> Update Plan </span> 
                  </li>
                  <li mwc-menu-item (click)="openCancelPopup()"> 
                    <span mwc-icon mwcIconfont="x-close" class="menu-icon"></span> 
                    <span class="mwc-color-gray-700 mwc-text-sm-medium"> Cancel </span> 
                  </li>
                </ng-template>
                <li mwc-menu-item (click)="logout()"> 
                  <span mwc-icon mwcIconfont="log-out-01" class="menu-icon"></span> 
                  <span class="mwc-color-gray-700 mwc-text-sm-medium"> Log out </span> 
                </li>
              </div>
            </div>
          </mwc-dropdown-menu>
        </div>
      </div>
    </mwc-header>
    <mwc-content>
      <router-outlet></router-outlet>
    </mwc-content>
  </mwc-layout>
</mwc-layout>

<mwc-modal [(mwcVisible)]="showSupportPopup" mwcWidth="544px" [mwcCentered]="true" (mwcOnCancel)="closeCancelSubscription(false)" [mwcFooter]="null">
  <ng-container *mwcModalContent>
    <div mwc-row [mwcGutter]="[16, 32]" style="padding-top: 24px; flex-flow: row;">
      <div mwc-col>
        <div class="mwc-icon-highlight-primary-lg">
          <span mwc-icon mwcIconfont="link-external-02" style="font-size: 20px;"></span>
        </div>
      </div>
      <div mwc-col>
        <ng-container>
          <p *ngIf="isCancelSubscription" class="mwc-text-sm-regular mwc-color-gray-600 m-0">To cancel your subscription plan, reach out to us at <b>customercare@milestoneinternet.com</b> with you registered email id.</p>
          <p *ngIf="!isCancelSubscription" class="mwc-text-sm-regular mwc-color-gray-600 m-0">Unable to load, please try later or contact us if issue persists</p>
        </ng-container>
      </div>
    </div>
    <div style="display: flex;justify-content: right;gap: 16px;" class="p-2 pb-4">
      <button mwc-button mwcType="primary" (click)="closeCancelSubscription(true)" mwcSize="small">Contact Us</button>
    </div>
  </ng-container>
</mwc-modal>
